import React, { useState, useEffect } from "react";
import { OTP,ErrorBoundary } from "./";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useTimer } from "../components/TimerContext";


const Verification = () => {
  const { time, isRunning, startTimer, resetTimer } = useTimer();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [reStatus, setReStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setTimeout(() => {
        setShowContent(true);
      }, 200);
    }, 200); // Adjust the time as needed
  }, []);
  

  const handleCaptchError = () =>{
    toast.warning('Fill in the Captcha', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      showOTP,
    });
  }

  const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleCaptcha = () => {
    setReStatus(true);
  };

  const handleClick = () => {
    if (phoneNumber.length === 10) {
      const requestNumber = phoneNumber;
      
      axios
        .get(`https://django.biscuitbobby.eu.org/get_otp/+91${requestNumber}/`)
        .then((response) => {
          var statusCode;
          response.status ? (statusCode = response.status) : (statusCode = 404);
          if (statusCode === 200) {
            console.log(statusCode);
            if (statusCode === 200) {
              toast.success(`OTP Sent` , {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });       
            }
            setShowOTP(true);
          } else if (statusCode === 404) {
            toast.error(
              "Invalid Number, to register for AmritaVarsham 70 go to ",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          } else if (statusCode === 403) {
            console.log(response)
            toast.warning(response.data, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              showOTP,
            });
            setShowOTP(true);
          } else {
            toast.error("Oops something went wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          var errorCode;
          error.response.status ? errorCode = error.response.status : errorCode=404 
          if (errorCode === 404) {
            toast.error(error.response.data, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else if (errorCode === 403) {
            toast.warning(error.response.data, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              showOTP,
            });
            setShowOTP(true);
          }
          else if (errorCode === 406) {
            toast.error('Invalid OTP', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              showOTP,
            });
            setShowOTP(true);
          }
          else {
            toast.error("Something went wrong. Please try again.", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    } else {
      toast.error("Enter Valid Phone Number", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (showOTP) {
      startTimer();
    }
  }, [showOTP, startTimer]);
  return (
    <ErrorBoundary>
    <div className={`loader ${loading ? '' : 'hide'} ${showContent ? 'gone' : ''}`} />
    <div className={`h-screen flex justify-center items-center mx-4 overflow-y-hidden content ${showContent ? 'show' : ''}`}>
      <div className="text-center flex flex-col gap-2 justify-center items-center">
        <div
          className={`transition-all transform ease-in-out duration-1000 ${
            showOTP ? "translate-y-0 opacity-100" : "translate-y-3/4 opacity-0"
          }`}
        >
          {showOTP ? <OTP phoneNumber={phoneNumber} /> : null}
        </div>
        {!showOTP && (
          <>
            <span className="font-bold text-2xl">Enter your phone number</span>
            <input
              type="number"
              className="w-full px-3 py-2 rounded-full border bg-white/5 shadow-sm text-sm sm:text-base"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={handlePhoneChange}
            />

            <button
              className="bg-amritaPurple text-white font-bold rounded-full w-1/2 p-3"
              onClick={handleClick} 
            >
              Continue
            </button>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
    </ErrorBoundary>
  );
};

export default Verification;
