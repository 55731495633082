import React, { useRef, useState, useEffect } from "react";
import { MemberSelector, Ticket } from "../components";
import backgroundVideo from "../assets/amma_Video.mp4";
import heroText from "../assets/Images/amritavarsham-70-blk-txt.svg";
import Logo from "../assets/Images/amritavarsham-70-blk.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Exclamation from "../assets/Images/exclamation-circle-svgrepo-com.svg";
import concertImage from "../assets/Images/concert.jpg";

const Main = () => {
  const [postBody, setPostBody] = useState({});
  const [response, setResponse] = useState("");

  const location = useLocation();
  const userData = location.state?.userData;  
  const phoneNumber = location.state?.phoneNumber;

  useEffect(() => {
    setResponse(location.state?.responseData);
  }, []);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const ticketRef = useRef();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const addTicket = ({
    UID,
    fullname,
    fname,
    lname,
    city,
    state,
    picLink,
    vf,
    vt,
    qr,
    lv,
  }) => {
    const newTicket = {
      UID,
      fullname,
      fname,
      lname,
      city,
      state,
      picLink,
      vf,
      vt,
      qr,
      lv,
    };
  
    let updatedTickets;
    let newData;
  
    if (tickets.some((ticket) => ticket.UID === UID)) {
      updatedTickets = tickets.filter((ticket) => ticket.UID !== UID);
      newData = { ...postBody.data };
      delete newData[UID];
    } else {
      updatedTickets = [...tickets, newTicket];
      newData = {
        ...postBody.data,
        [UID]: fullname,
      };
    }
  
    setTickets(updatedTickets);
    setPostBody({
      data: newData,
      num: phoneNumber,
    });
  };
  

  const handleContinue = () => {
    console.log(userData);
    if (tickets.length > 0) {
      toggleConfirmation();
    } else {
      toast.error("Select Attendees", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const navigate = useNavigate();
  const togglePopup = () => {
    navigate("/download", {
      state: { tickets: tickets },
    })
  };

  const toggleConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  };

  const pdfRef = useRef(null);
  useEffect(() => {
    if (showPopup) {
    }
  }, [showPopup]);
  return (
    <div className="font-Unna font-medium overflow-y-hidden">
      {/* big screen */}
      <div className={`ipad:flex flex-row justify-center items-center w-full h-full hidden bg-white`}>
        <div className="max-w-lg h-full z-50 py-2 px-6">
          <div className="max-w-full h-full  rounded-lg p-6 backdrop-blur">
            <div className="flex flex-col justify-center items-center h-full rounded-lg gap-8  border-[2.5px] border-amritaPurple-400">
              <div className="flex flex-col text-4xl">
                <div className="text-black font-light text-center">
                  <img src={Logo} className="lg:w-[300px] w-[200px]" />
                  Namah Shivaya
                </div>
                <div className="text-black font-extrabold text-center">
                  {phoneNumber}
                </div>
                <div className="w-full px-10 pt-2">
                  <div className="border-b-2 border-black" />
                </div>
              </div>
              <div className="w-2/3 text-center text-2xl">
                <p className="text-black">
                  Join us at Amrita Varsham 2023 with your beloved family.
                  Please select attendees from the provided list for a perfect
                  visit
                </p>
              </div>
              <div className="flex font-bold flex-col items-center gap-3 w-1/2 h-full">
                {Object.values(userData).map((user) => (
                  <MemberSelector
                    key={user.UID}
                    fname={user.fname}
                    lname={user.lname}
                    onClick={() =>
                      addTicket({
                        UID: user.id,
                        fname: user.fname,
                        lname: user.lname,
                        city: user.city,
                        state: user.state,
                        picLink: user.image,
                        vf: user.Valid_from,
                        vt: user.Valid_through,
                        lv: user.last_visited,
                        qr: user.qrcode,
                      })
                    }
                  />
                ))}
              </div>
              <div className="pb-4">
              <button
                  className={`text-lg font-extrabold p-2 rounded-xl border-[3px] border-black ${tickets.length!=0 ? `text-white bg-amritaPurple-100`: `text-black `}`}
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>


        {showConfirmation && (
          <div
            className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
            onClick={toggleConfirmation}
          >
            <div className="flex flex-col justify-center items-center gap-5 w-1/4 bg-white max-h-min border-4 border-black p-4 rounded-2xl">
              <img src={Exclamation} width={100} height={100} />
              <p className="text-black text-3xl text-center px-10 pb-2">
                Are you sure that this is yout final selection?
              </p>
              <div className="flex flex-row gap-3 w-full justify-center">
                <button
                  className="text-white font-semibold text-xl bg-amritaPurple rounded-full w-1/3 whitespace-nowrap border-white border-2 p-2"
                  onClick={togglePopup}
                >
                  Yes
                </button>
                <button
                  className="text-amritaPurple text-xl font-semibold bg-white rounded-full w-1/3 whitespace-nowrap border-amritaPurple-400 border-2 p-2"
                  onClick={toggleConfirmation}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* mobile screen */}
      <div className={`flex flex-col gap-12 lg:flex-row ipad:hidden justify-center items-center bg-white`}>
        <div className="w-full bg-white rounded-b-xl flex items-center justify-center border-b-[2.5px] border-amritaPurple-400 z-60">
          <div className="p-4 pb-5 rounded-b-xl bg-amritaPurple-00/70 invert">
            <img src={heroText} height={20} width={500} />
          </div>
        </div>

        <div className="w-full h-full max-w-lg px-3 flex flex-col justify-center items-center gap-12">
          <div className="relative max-w-full justify-center items-center rounded-lg p-3 overflow-y-auto border-[2.5px] border-amritaPurple-400">
            <div className="flex flex-col py-5 items-center h-full bg-white rounded-lg gap-4 border-2 border-white">
              <div className="flex flex-col w-full">
                <div className="text-black text-xl font-medium text-center">
                  Namah Shivaya
                </div>
                <div className="text-black font-extrabold text-2xl text-center">
                  {phoneNumber}
                </div>
                <div className="w-full px-10 pt-2">
                  <div className="border-b-2 border-black" />
                </div>
              </div>
              <div className="w-5/6 text-center flex flex-col gap-5 text-lg">
                <div>
                  <p className="">
                    Join us at Amrita Varsham 2023 with your beloved family.
                    Please select attendees from the provided list for a perfect
                    visit
                  </p>
                </div>
                <div className="flex flex-col justify-center items-center gap-3">
                  {Object.values(userData).map((user) => (
                    <MemberSelector
                      key={user.UID}
                      fname={user.fname}
                      lname={user.lname}
                      onClick={() =>
                        addTicket({
                          UID: user.id,
                          fname: user.fname,
                          lname: user.lname,
                          city: user.city,
                          state: user.state,
                          picLink: user.image,
                          vf: user.Valid_from,
                          vt: user.Valid_through,
                          lv: user.last_visited,
                          qr: user.qrcode,
                        })
                      }
                    />
                  ))}
                </div>
              </div>
              <div>
                <button
                  className={`text-lg font-extrabold p-2 rounded-xl border-[3px] border-black ${tickets.length!=0 ? `text-white bg-amritaPurple-100`: `text-black `}`}
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center gap-4 text-black">
            <span className="text-3xl font-bold text-center">
              Amrita Varsham 2023
              <div className="pt-2 w-full px-5">
                <div className="border-b-2 border-black shadow-xl" />
              </div>
            </span>
            <div className="p-1 bg-white rounded-xl">
              <video
                src={backgroundVideo}
                autoPlay
                loop
                playsInline
                muted
                className="w-full object-cover rounded-xl"
              />
            </div>
            <p className="text-center pb-10">
              Amma, the Mother of Millions, has revitalized India's
              spirituality, strengthened society's moral fabric, and performed
              selfless service, providing free healthcare, food, shelter,
              education, and more to the underprivileged. Her private university
              drives scientific research benefiting the poor. Join the joyous
              celebration of her birthday, a confluence of devotees worldwide,
              and witness humanitarian projects unveiling.
            </p>
          </div>
        </div>


        {showConfirmation && (
          <div
            className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center"
            onClick={toggleConfirmation}
          >
            <div className="flex flex-col justify-center items-center gap-5 bg-white max-h-min w-full border-4 border-black p-4 rounded-2xl">
              <img src={Exclamation} width={50} height={50} />
              <p className="text-black text-2xl text-center px-10">
                Are you sure that this is yout final selection?
              </p>
              <div className="flex flex-row gap-3 w-full justify-center">
                <button
                  className="text-white text-xl bg-amritaPurple rounded-full w-1/3 whitespace-nowrap border-white border-2 p-2"
                  onClick={togglePopup}
                >
                  Yes
                </button>
                <button
                  className="text-amritaPurple text-xl font-semibold bg-white rounded-full w-1/3 whitespace-nowrap border-amritaPurple-400 border-2 p-2"
                  onClick={toggleConfirmation}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Main;
