import React, { useState } from "react";

const MemberSelector = ({ fname, lname, onClick }) => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleClickYes = () => {
    setIsButtonClicked(!isButtonClicked);
    // Call the onClick function passed from the parent (App) with the sel3cted member's data
    onClick({ fname, lname, city: "mahe", state: "kerala", picLink: "this" });
  };

  return (
    <button
      className={`p-4 flex font-extrabold justify-center items-center w-3/4 rounded-xl text-black border-[3px] border-black font-Unna text-xl  ${
        isButtonClicked
          ? " transition ease-in duration-100 bg-amritaPurple-100 text-white font-bold border-amritaPurple-400"
          : "bg-white/10 border-black"
      }`}
      onClick={handleClickYes}
    >
      {fname} {lname}

    </button>
  );
};

export default MemberSelector;
