import React, { useEffect,useState } from 'react';
import qrCode from "../assets/Images/randomqr-256.png";
import axios from "axios";
import QRCode from 'react-qr-code';

const Ticket = ({ Id, picLink, fname, lname, city, state,vf,vt,qr,lv }) => {
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    axios.get(picLink, { responseType: 'blob' })
      .then(response => {
        const blob = response.data;
        const url = URL.createObjectURL(blob);
        setImageURL(url);
      })
      .catch(error => {
        console.error('Error fetching the image:', error);
      });
  }, []);
  return (
    <div id="ticket">
      <div className="text-center h-full w-full lg:w-full bg-white text-black border-2 border-black rounded-sm p-5">
          <div>
            <h1 className="font-bold text-2xl">Amritavarsham 70</h1>
            <h2 className="font-bold text-xl">Online Registration Pass</h2>
            <span className="text-lg">
              Amritapuri P.O Kollamm Kerala 690525,<br></br>
            </span>
            <span className="text-lg">Ph:(0496)3241066</span>
          </div>

          <div className="flex gap-7 w-full justify-center items-center py-10">
            <img className="w-1/2 lg:w-2/5 border-2 border-black p-2" alt="id-image" src={imageURL} />
            <QRCode className='w-1/2 p-6'
            value={qr} />
          </div>

          <span className="text-xl font-bold pb-3">
            {fname} {lname}
          </span>
          <div className="grid grid-cols-2 gap-3 text-lg">
            <div className="text-start">City</div>
            <div className="text-start">: {city}</div>
            <div className="text-start">State</div>
            <div className="text-start">: {state}</div>
            <div className="text-start">Valid From</div>
            <div className="text-start">: {vf}</div>
            <div className="text-start">Valid Till</div>
            <div className="text-start">: {vt}</div>
          </div>
          <span className="text-start w-full font-bold text-xl">
            <div className="text-left py-10">Last Visit: {lv}</div>
          </span>
      </div>
    </div>
  );
};

export default Ticket;
