import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { Ticket } from "../components";
import { useReactToPrint } from "react-to-print";
import { browserName, browserVersion } from "react-device-detect";

const Download = () => {
  const location = useLocation();
  const tickets = location.state.tickets;
  console.log(`${browserName}, ${browserVersion}`);
  const pdfRef = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "tickets.pdf",
    content: () => pdfRef.current,
  });

  return (
    <div className="lg:p-4 w-full flex flex-col gap-2 mt-2 items-center justify-center">
      <div className="flex justify-center  w-full file: lg:w-1/4">
        <div className="flex flex-row gap-4 justify-center w-full">
          <button
            onClick={handlePrint}
            className="text-white text-lg font-semibold bg-amritaPurple rounded-2xl w-1/2 lg:w-1/3 border-white border-[3px] p-4"
          >
            Download
          </button>
        </div>
      </div>
      <div ref={pdfRef} className="max-h-min">
        {tickets.map((ticket, index) => (
          <Ticket
            key={ticket.UID} // Use UID as the key instead of the index
            picLink={ticket.picLink}
            fname={ticket.fname}
            lname={ticket.lname}
            city={ticket.city}
            state={ticket.state}
            vf={ticket.vf}
            vt={ticket.vt}
            lv={ticket.lv}
            qr={ticket.qr}
          />
        ))}
      </div>
    </div>
  );
};

export default Download;
