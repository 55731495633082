import 'chaya-ui/dist/style.css'; 
import './App.css';
import {Download, Main, OTP, Verification, timer} from './pages';
import TimerComponent from './pages/timer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


function App() {
  return(
  // <DSRContextProvider>
  <BrowserRouter>
  <Routes>
    <Route path='/otp' element={<OTP />} />
    <Route path='/main' element={<Main />} />
    <Route path='/' element={<Verification />} />
    <Route path='/download' element={<Download />} />
  </Routes>
  </BrowserRouter>
  // </DSRContextProvider>
  )
}

export default App;
