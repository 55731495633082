import React, { useEffect, useState } from "react";
// import useCountDown from 'react-countdown-hook';
import { ErrorBoundary } from "./";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Button, PinInput } from "chaya-ui";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TimerProvider, useTimer } from "../components/TimerContext";


function OTPVerification({ phoneNumber }) {
  const { time, isRunning ,resetTimer } = useTimer();

  const num = "+91" + phoneNumber;

  const handleOTPRetry = () => {
    resetTimer();  
    axios
      .get(`https://django.biscuitbobby.eu.org/get_otp/${num}/`)
      .then((response) => {
        console.log(response.status);
        const statusCode = response.status;
        if (statusCode === 200) {
          toast.success("OTP Sent", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });       
        }
      })
      .catch((error) => {
        console.log("this is the error",error.response.status);
        if (error.response.status) {
          toast.warning(error.response.data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const handleEmptyOTP = () => {
    toast.warning("Enter the OTP", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleError406 = () => {
    toast.error("Invalid OTP", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleGenericError = () => {
    toast.error("Something went wrong. Try again.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const [otpString, setOTPString] = useState("");

  const handleInputChange = (event, index) => {
    const newValue = event.target.value;
    const newOTPString =
      otpString.slice(0, index) + newValue + otpString.slice(index + 1);
    setOTPString(newOTPString);
  };

  const navigate = useNavigate();
  const cancelTokenSource = axios.CancelToken.source();

  const abortController = new AbortController();
  const signal = abortController.signal;

  const APILink = "https://django.biscuitbobby.eu.org/details/all/";
  const handleOTP = async () => {
    try {
      if (otpString.length === 0) {
        handleEmptyOTP();
      } else {
        const requestBody = {
          num: num,
          otp: otpString,
        };

        await axios.post(APILink, requestBody, { signal }).then((response) => {
          if (response.status === 200) {
            navigate("/main", {
              state: { userData: response.data, phoneNumber: phoneNumber },
            });
          } else {
            handleGenericError();
          }
        });
      }
    } catch (error) {
      if(error.response.status === 406){
        handleError406();
      }else{
        handleGenericError();
      }
       
    }
  };

  return (
    <ErrorBoundary>
      <div className="flex h-screen bg-white py-20 px-3 justify-center items-center overflow-y-hidden">
        <div className="container mx-auto">
          <div className="max-w-sm mx-auto md:max-w-lg">
            <div className="w-full">
              <div className="bg-white h-1/2 py-3 rounded text-center">
                <h1 className="text-2xl font-bold">OTP Verification</h1>
                <div className="flex flex-col mt-4">
                  <span>Enter the OTP you received at</span>
                  <span className="font-bold">{phoneNumber}</span>
                </div>

                <div id="otp" className="px-2">
                  <PinInput
                    variant="classic"
                    value={otpString}
                    type="number"
                    digits={6}
                    onChange={setOTPString}
                  />
                </div>

                <div className="flex flex-row justify-center items-center gap-2 text-center mt-5">
                  <button
                    className="font-bold bg-amritaPurple text-white w-32  py-4 rounded-full"
                    onClick={handleOTP}
                  >
                    OK
                  </button>
                  <button
                    className={`font-bold text-white px-6 py-4 rounded-full bg-amritaPurple`}
                    onClick={handleOTPRetry}
                  >
                    Resend OTP
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default OTPVerification;
